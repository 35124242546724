@import 'styles/variables.less';
@import 'styles/mixins.less';

.TeamProfileInfo {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  @media @desktop {
    padding: 0;
  }

  &__Wrapper {
    display: grid;
    grid-template-columns: 1fr 14rem 1fr;
    margin-top: -6.8rem;
    justify-content: space-between;
    align-items: end;
    
    @media @desktop {
      margin-top: -8.8rem;
    }
  }
  
  &__LeftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 4.4rem;
    overflow: hidden;
    gap: 0.8rem;
  }
  
  &__AvatarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  &__Avatar {
    width: 13.2rem;
    border: solid 0.4rem @black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    @media @desktop {
      margin-bottom: 1.6rem;
      border: solid 0.8rem @black;
    }
  }
  
  &__TeamInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    height: 1.6rem;
  }
  
  &__Follow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.6rem;
    overflow: hidden;
    
    @media @desktop {
      padding-bottom: 1.2rem;
    }
  }

  &__Name {
    .truncateWithEllipsis();
    height: 4.4rem;
    width: 100%;
    color: @white;
    align-items: center;
    justify-content: center;
    display: flex;

    @media @desktop {
      margin-bottom: 0.4rem;
    }
  }

  &__TeamCity {
    .comment();
    .truncateWithEllipsis();
    color: @grey2;
    max-width: 9rem;
   
    @media @desktop {
      max-width: 13rem;
    }
  }

  &__City {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: inherit;
    height: 2rem;
  }
  
  &__Results {
    .comment();
    padding: 0.8rem 0;
    color: @grey2;
  }
}

.Conference {
  .comment();
  
  padding: 0.8rem 0;
  color: @grey2;
}

.Position {
  .commentBold();
  
  color: @white;
}

.Separator {
  .body();
  display: flex;
  color: @grey3;
}
