@import 'styles/variables';

.CreatePollPostForm {
  &__Footer {
    display: flex;
    margin-top: 3.2rem;
  }

  &__PollWrapper {
    margin-top: 0.8rem;
  }

  &__CreateBtn {
    position: fixed;
    bottom: @safeAreaBottom;
    right: 0;
    padding: @verticalSpacingMobile @bigHorizontalSpacingMobile;
    border-top: 0.1rem solid @transparentLight3;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: @black;

    @media @desktop {
      position: relative;
      background-color: transparent;
      border-top: unset;
      padding: 0;
    }
  }
}
