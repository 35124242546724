@import 'styles/variables.less';

.Toolbar {
  display: flex;
  gap: 0.8rem;
  margin: 0.8rem 0;

  &--stick-right {
    margin-left: auto;
  }
  
  &--full-width {
    width: 100%;
  }
  
  &--single-media {
    justify-content: space-between;
    align-items: center;
  }
  
  &--submit {
    margin-right: 1rem;
  }
}

.Video {
  display: none;
}


.SingleImage {
  border-radius: @borderRadius;
  position: relative;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
}

.SingleVideo {
  border-radius: @borderRadius;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  position: relative;
}

.VideoToolbar {
  padding: 2.4rem 0 ;
  gap: 1.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ToolbarWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
  &--full-width {
    justify-content: space-between;
    width: 100%;
  }
}

.ToolbarItems {
  display: flex;
  gap: 0.8rem;
  flex-direction: row;
}
