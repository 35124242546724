@import 'styles/variables.less';
@import 'styles/mixins.less';

.ContentEditable {
  .contentEditable();

  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - @createPostHeightWithoutEditor);
  box-sizing: border-box;
  background-color: @grey7;
  padding: 1.6rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid transparent;

  & > p:last-of-type {
    margin-bottom: 0;
  }

  a {
    .truncateLink();
  }
  
  &--height-22 {
    height: 22.4rem;
  }

  &--height-12 {
    height: 12.8rem;
  }
  
  &--active {
    border: 0.1rem solid @normal;
  }
  
  &--error {
    border: 0.1rem solid @alert;
  }
  
  &--comment-desktop {
    min-height: 5rem;
    max-height: 12.8rem;
    background-color: @grey8;
  }
}
