@import 'styles/variables.less';

.SingleGif {
  width: 6.4rem;
  position: relative;
  max-height: 6.4rem;
  overflow: hidden;
  border-radius: @borderRadius;
  display: inline-block;

  &--extra-small {
    width: 3.8rem;
    max-height: 3.8rem;
  }

  &--small {
    width: 6.4rem;
    max-height: 6.4rem;
  }

  &--medium {
    width: 11.2rem;
    max-height: 11.2rem;
  }

  &--large {
    width: 30.2rem;
    max-height: 30.2rem;
  }
  
  &--size80 {
    width: 8rem;
    max-height: 8rem;
  }
}

.GifIcon {
  display: none;
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 2.8rem;
  height: 1.5rem;
  z-index: 1;
  color: @white;
  background-color: @black;
  opacity: 0.8;
  border-radius: @borderRadius;
  align-items: center;
  justify-content: center;

  &--visible {
    display: flex;
    background-image: url('assets/images/svg/gif-label.svg');
  }
}
