@import 'styles/variables.less';

.FeedEmptyState {
  & > div {
    min-height: 50vh;

    @media @desktop {
      min-height: 30rem;
      padding: 0 2.4rem;
    }
  }

  &--player-feed {
    padding-top: 1.6rem;
    
    & > div {
     
      min-height: 50vh;

      @media @desktop {
        min-height: 30rem;
      }
    }
  }
}
